@import "../../scss/main";

.loadingElement {
  @include flex(
          $align-items: center,
          $justify-content: center
  );
  
  width: 100%;
  height: 100%;

  .loadingImage {

    max-width: 100px;
    max-height: 100px;
    margin: margin-step(5);
  }
}

.dark-theme {

  .loadingImage {
    //border-radius: 50%;
    //border-color: #333333;
    //box-shadow: 0px 1px 3px rgba(244, 244, 244, 0.3) inset, 0px 0px 8px rgba(244, 244, 244, 0.6);
  }
}