@mixin flex(
  $flex: false,
  $display: false,
  $flex-direction: false,
  $align-items: false,
  $justify-content: false
){
  @if $flex{ flex: $flex; }
  @if $display{ display: $display; } @else { display: flex; }
  @if $flex-direction{ flex-direction: $flex-direction; } @else { flex-direction: initial; }
  @if $align-items{ align-items: $align-items; } @else { align-items: initial; }
  @if $justify-content{ justify-content: $justify-content; } @else { justify-content: initial; }
}
